import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Route } from "react-router-dom";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";

import Section from "../../components/section/Section";
import TextField from "@mui/material/TextField";
import axios from 'axios';
import FromControl from "@mui/material/FormControl";
//Images
import Maps from "./Maps.png";

//ICONS
import DateRangeIcon from '@mui/icons-material/DateRange';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HealingIcon from '@mui/icons-material/Healing';
import EntryStatusModal from './EntryStatusModal';
import { Stack } from '@mui/material';

const Home = () => {
  const [entryId, setEntryId] = useState('');
  const [entryStatus, setEntryStatus] = useState({
    status: '',
    secondaryStatus: '',
    entryDate: '',

  });
  const [modalOpen, setModalOpen] = useState(false);
  const [statusInput, setStatusInput] = useState({
    idLeft: '',
    idRight: '',
  });


  const inputChangeHandler = (event) => {

    setStatusInput((prevState) => {
      return { ...prevState, [event.target.id]: event.target.value }
    })

  }

  const onKeyDownHandler = (e) => {
    // e.preventDefault();
  }

  const fetchEntryState = async () => {
    try {
      const url = `https://dalvado.es:9981/legacy/public/status/${statusInput.idLeft}/${statusInput.idRight}`;

      const response = await axios.get(url);
      
      if (response.data.length < 1) return;
      const parsedDate = new Date(response.data[0].entry_date).toISOString().split('T')[0];

      setEntryStatus({ ['status']: response.data[0].entry_state, ['secondaryStatus']:response.data[0].entry_sub_state, ['entryDate']: parsedDate });
      setModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }
  const handleCloseModal = () => {
    setModalOpen(false);
  }

  return (
    <React.Fragment>
      <EntryStatusModal open={modalOpen} handleClose={handleCloseModal} status={entryStatus['status']} secondaryStatus={entryStatus.secondaryStatus} entryDate={entryStatus['entryDate']} />
      {/* SECTION 1 */}

      <Section background='linear-gradient(to right bottom, #0E1C35, #080919)' padding="10%" alignItems="center" color="white" zIndex={3} spacing={7} content={
        <React.Fragment>
          <Grid item lg={6}>

            <Box >
              <Typography
                align="left"
                fontWeight="600"
                fontSize="3.25rem"
                component="h1"
                lineHeight="1.1"
                marginBottom="25px"
              >
                Si se enchufa se repara! Empieza la reparación ahora!
              </Typography>


              <Button

                sx={{ marginTop: "15px", backgroundColor: "#0071E3", textTransform: 'none' }}
                variant="contained"
                disableElevation
                fullWidth
                component={RouterLink} to="/reparacion"
              >
                <Box paddingX="15px" paddingY="5px">
                  Comenzar a reparar
                </Box>
              </Button>
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Paper sx={{ borderRadius: "20px" }}>
              <Box padding="5%">
                <Typography
                  fontWeight="600"
                  fontSize="2rem"
                  marginBottom="15px"
                  component="h2"
                  align="center"
                >
                  Verificar estado

                </Typography>
                <Typography
                  fontSize="1.125rem"
                  marginBottom="10%"
                >
                  Verifique el estado de su dispositivo utilizando el código recibido en su correo electrónico y / o en el número de teléfono proporcionado a nuestro departamento
                </Typography>

                <Box textAlign="center" margin="5%">
                </Box>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <TextField
                    variant='filled'
                    id="idLeft"
                    value={statusInput.idLeft}
                    type={'text'}
                    onChange={inputChangeHandler} width={'100%'} hiddenLabel />
                  <Typography variant='h5'>#</Typography>
                  <TextField type={'text'} value={statusInput.idRight}
                    variant='filled' id="idRight" onChange={inputChangeHandler} fullWidth hiddenLabel />
                </Stack>
                {/* <Typography>En mantenimiento...</Typography> */}
                <Button
                  type="button"
                  sx={{ marginTop: "15px", backgroundColor: "#0071E3", textTransform: 'none' }}
                  variant="contained"
                  disableElevation
                  onClick={fetchEntryState}
                >
                  <Box paddingX="15px" paddingY="5px">
                    Verificar estado
                  </Box>
                </Button>

              </Box>
            </Paper>

          </Grid>
        </React.Fragment>
      } />
      <Section backgroundColor="#E7F2FF" sectionTransform="skewY(-3deg)" contetTransfrom="skewY(3deg)" marginTop='-5%' padding='10%' zIndex={1} content={
        <React.Fragment>
          <Typography
            align="left"
            fontWeight="600"
            fontSize="2rem"
            marginBottom="15px"
            component="h2"
          >
            ¡Dispositivos de vuelta a la vida!
          </Typography>
          <Typography
            align="left"
            fontSize="21px"
          >
            Nuestros mejores amigos nos guían por GPS,crean momentos fabulosos tomando fotos,guardan notas prácticas ,navegan a través del internet y nos conectan con los contactos cada día.

          </Typography>
          <Typography
            align="left"
            fontSize="1.3rem"
          >
            Pero, a veces puede ser complicado al dañarse o simplemente por envejecer. No te preocupes, tenemos las herramientas adecuadas para cualquier tipo de problema.
          </Typography>
        </React.Fragment>
      } />
      <Section content={
        <React.Fragment>
          <Grid item xs={12} lg={4}>
            <Box textAlign="center">
              <svg width={0} height={0}>
                <linearGradient id="DateRangeIcon" x1={1} y1={0} x2={1} y2={1}>
                  <stop offset={0} stopColor="#80D24B" />
                  <stop offset={1} stopColor="#48C694" />
                </linearGradient>
              </svg>
              <DateRangeIcon sx={{ fontSize: 44, margin: "auto", fill: "url(#DateRangeIcon)" }} />
            </Box>
            <Typography
              textAlign="center"
              fontWeight="500"
              fontSize="24px"
              marginBottom="15px"
            >
              3 meses de garantía

            </Typography>
            <Typography
              color="light.main"
              textAlign="center"
              fontWeight="500"
              fontSize="18px"
              marginBottom="15px"
            >
              Ofrecemos 90 días de garantía para nuestro servicio
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box textAlign="center">
              <svg width={0} height={0}>
                <linearGradient id="SmartphoneIcon" x1={1} y1={0} x2={1} y2={1}>
                  <stop offset={0} stopColor="#FC383C" />
                  <stop offset={1} stopColor="#FE7F2D" />
                </linearGradient>
              </svg>

              <SmartphoneIcon sx={{ fontSize: 44, margin: "auto", fill: "url(#SmartphoneIcon)" }} />
            </Box>
            <Typography
              textAlign="center"
              fontWeight="500"
              fontSize="24px"
              marginBottom="15px"
            >
              Reemplazo del teléfono
            </Typography>
            <Typography
              color="light.main"
              textAlign="center"
              fontWeight="500"
              fontSize="1.125rem"
              marginBottom="15px"
            >
              Mientras su dispositivo esté en servicio, podemos proporcionarle un teléfono temporal.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box textAlign="center">
              <svg width={0} height={0}>
                <linearGradient id="AccessTimeIcon" x1={1} y1={0} x2={1} y2={1}>
                  <stop offset={0} stopColor="#3489F6" />
                  <stop offset={1} stopColor="#1F79EA" />
                </linearGradient>
              </svg>

              <AccessTimeIcon sx={{ fontSize: 44, margin: "auto", fill: "url(#AccessTimeIcon)" }} />
            </Box>
            <Typography
              textAlign="center"
              fontWeight="500"
              fontSize="24px"
              marginBottom="15px"
            >
              Reparacion express
            </Typography>
            <Typography
              color="light.main"
              textAlign="center"
              fontWeight="500"
              fontSize="1.125rem"
              marginBottom="15px"
            >
              El dispositivo se repara en 1 hora si la pieza necesaria está en stock
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              fontWeight="700"
              fontSize="72px"
              sx={{
                background: "-webkit-linear-gradient(70deg, #FF8E53 30%, #FE6B8B 60%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
              }}
            >
              <svg width={0} height={0}>
                <linearGradient id="HealingIcon" x1={1} y1={0} x2={1} y2={1}>
                  <stop offset={0} stopColor="#FE6B8B" />
                  <stop offset={1} stopColor="#FF8E53" />
                </linearGradient>
              </svg>
              <HealingIcon sx={{ fontSize: "42px", fill: "url(#HealingIcon)" }} />
              56.283
              <HealingIcon sx={{ fontSize: "42px", fill: "url(#HealingIcon)" }} />
            </Typography>
            <Typography
              color="light.main"
              textAlign="center"

              marginBottom="15px"
              variant="h4"
            >
              Dispositivos reparados y continuamos
            </Typography>
          </Grid>

        </React.Fragment>
      } />
      {/* <Section backgroundColor="#E7F2FF" sectionTransform="skewY(-3deg)" contetTransfrom="skewY(3deg)" padding="5%" zIndex={1} content={
        <React.Fragment>
          <Grid item lg={6} xs={12}>
            <Typography
              align="left"
              fontWeight="600"
              fontSize="42px"
              marginBottom="15px"
              component="h2"
              lineHeight="1"
            >
              Nuestro servicio a través de socios.
            </Typography>
            <Typography
              align="left"
              fontSize="21px"
            >
              ¡Para estar lo más cerca posible de usted, estamos en constante crecimiento! Si necesita ayuda, vaya a cualquier servicio firmado “dalvado”.

            </Typography>
            <Box justifyContent="left">
              <Button

                sx={{ marginTop: "15px", backgroundColor: "#0071E3", textTransform: 'none' }}
                variant="contained"
                disableElevation
                fullWidth
              >
                <Box paddingX="15px" paddingY="5px">
                  Ver la Mapa
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box>
              <Box
                component="img"
                sx={{ maxWidth: "100%", padding: "5%" }}
                alt="maps"
                fit
                src={Maps}
              />
            </Box>
          </Grid>
        </React.Fragment>
      } /> */}


    </React.Fragment>
  );
};

export default Home;

