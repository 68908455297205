import * as React from 'react';
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';




const ShadowPaper = styled(Paper)((props) => ({
  boxShadow: `rgba(34, 79, 169, ${props.alphaOne}) 0px 50px 100px 0px, rgba(255, 255, 255, 0.6) 0px 0px 0px ${props.innerShadow} inset`,
  }));

  ShadowPaper.defaultProps = {
    alphaOne: "0.2",
    innerShadow: "0.5px",
  }
  
  export default  ShadowPaper;