import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

const Footer = () => {
    return (
        <React.Fragment>
            < AppBar position="static"  sx={{color:"white",backgroundColor:"#0E1C35", marginTop:"calc(5% + 60px)"}}  >
                <Container >
                    <Box padding={10}>
                        <Typography variant="body1" color="inherit" textAlign="center">
                            © 2021 Dalvado
                        </Typography>
                    </Box>
                </Container>
            </AppBar >

        </React.Fragment>
    )
}

export default Footer;