import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ShapeDivider from "../shape-divider/ShapeDivider";


const Section = (props) => {
  return (
    <Box sx={props.csx} position={'relative'} marginTop={props.marginTop} padding={props.padding} backgroundColor={props.backgroundColor} color={props.color}
      sx={{
        background: props.background,
        zIndex: props.zIndex,
        transform: props.sectionTransform,
        borderBottomLeftRadius: props.borderBottomLeftRadius,
        borderBottomRightRadius: props.borderBottomRightRadius
      }}>
      <Container sx={{ transform: props.contetTransfrom, zIndex: props.zIndex }}>
        <Grid container alignItems={props.alignItems} zIndex={props.zIndex} spacing={props.spacing}>
          {props.content}
        </Grid>
      </Container>
    </Box>)
}

Section.defaultProps = {
  padding: "5%",
  alignItems: "center",
  spacing: 2,
}

export default Section;