import React, { useState } from 'react';

import { Box } from '@mui/system';
import Typography from "@mui/material/Typography";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';


const EntryStatusModal = (props) => {

    const statuses = {
        'In Queue': { color: 'inQueue', variant: 'contained', fontColor: 'white', name: 'En Cola' },
        'Repaired': { color: 'inQueue', variant: 'contained', fontColor: 'white', name: 'Reparado' },
        'Diagnose': { color: 'diagnose', variant: 'contained', fontColor: 'white' },
        'Notify Customer': { color: 'notifyCustomer', variant: 'contained' },
        'Accepted': { color: 'accepted', variant: 'contained' },
        'Waiting For Customer Decision': { color: 'waitingForCustomerDecision', variant: 'contained' },
        'In Repair': { color: 'inRepair', variant: 'contained' },
        'Waiting For Parts': { color: 'waitingForParts', variant: 'contained' },
        'In Delivery': { color: 'inDelivery', variant: 'contained' },
        'Quality Assurance': { color: 'qualityAssurance', variant: 'contained' },
        'Ready For Pick Up': { color: 'readyForPickUp', variant: 'contained' },
        'Delivered To Customer': { color: 'deliveredToCustomer', variant: 'contained' },
        // 'Request': { color: 'inQueue', variant: 'outlined' },
        // 'Delivered': { color: 'deliveredToCustomer', variant: 'outlined' },
        'default': { color: 'darkBlue', variant: 'contained' },
    }

    const getStatusName = (status) => {
        switch (status) {
            case "Accepted":
                return "Acceptado"
                break;
            case "Contact":
                return "Comunicarse al cliente"
                break;
            case "Client Decision":
                return "Decisión del cliente"
                break;
            case "Diagnose":
                return "Diagnostico"
                break;
            case "In Repair":
                return "En reparación"
                break;
            case "Waiting Parts":
                return "Esperando piezas"
                break;
            case "Warranty":
                return "Garantia"
                break;
            case "Irreparable":
                return "Irreparable"
                break;
            case "Irreproducible":
                return "Irreproducible"
                break;
            case "Testing":
                return "Control de calidad"
                break;
            case "Declined":
                return "Rechazó"
                break;
            case "Repaired":
                return "Reparado"
                break;
            case "Request":
                return "Solicitud"
            default:
                return status
        }
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "Accepted":
                return "accepted"
                break;
            case "Contact":
                return "darkBlue"
                break;
            case "Client Decision":
                return "darkBlue"
                break;
            case "Diagnose":
                return "darkBlue"
                break;
            case "In Repair":
                return "secondary"
                break;
            case "Waiting Parts":
                return "darkGrey"
                break;
            case "Warranty":
                return "darkGrey"
                break;
            case "Irreparable":
                return "red"
                break;
            case "Irreproducible":
                return "darkBlue"
                break;
            case "Testing":
                return "darkBlue"
                break;
            case "Declined":
                return "red"
                break;
            case "Repaired":
                return "primary"
                break;
            case "Request":
                return "inQueue"
            default:
                return "darkBlue"
        }
    }

    // 'In Queue': { color: 'inQueue', variant: 'contained', fontColor:'white' },
    // 'Diagnose': { color: 'diagnose', variant: 'contained', fontColor: 'white' },
    // 'Notify Customer': { color: 'notifyCustomer', variant: 'contained' },
    // 'Accepted': { color: 'accepted', variant: 'contained' },
    // 'Waiting For Customer Decision': { color: 'waitingForCustomerDecision', variant: 'contained' },
    // 'In Repair': { color: 'inRepair', variant: 'contained' },
    // 'Waiting For Parts': { color: 'waitingForParts', variant: 'contained' },
    // 'In Delivery': { color: 'inDelivery', variant: 'contained' },
    // 'Quality Assurance': { color: 'qualityAssurance', variant: 'contained' },
    // 'Ready For Pick Up': { color: 'readyForPickUp', variant: 'contained' },
    // 'Delivered To Customer': { color: 'deliveredToCustomer', variant: 'contained' },
    // // 'Request': { color: 'inQueue', variant: 'outlined' },
    // // 'Delivered': { color: 'deliveredToCustomer', variant: 'outlined' },
    // 'default': { color: 'darkBlue', variant: 'contained' },


    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth='xs'>
            <DialogTitle> Estado de la reparacion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fecha de entrada: {props.entryDate}
                </DialogContentText>
                <Stack alignItems={'start'} spacing={2} >
                    <Chip color={getStatusColor(props.status)} label={getStatusName(props.status)} />
                    {/* {props.secondaryStatus ? <Chip label={props.secondaryStatus} /> : null} */}
                </Stack>

            </DialogContent>
            <DialogActions>

                <Button onClick={props.handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EntryStatusModal;