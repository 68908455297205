import logo from "./logo.svg";
import Header from "./components/header/header";
import Home from "./pages/home/Home";
import AboutUs from "./pages/about-us/AboutUs";
import ContactUs from "./pages/contact-us/ContactUs";
import RepairForm from "./pages/repair-form/RepairForm";
import NoMatch from "./pages/no-match/NoMatch";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import { AppBar, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Router } from "react-router-dom";
import Footer from "./components/footer/Footer";
import { Redirect } from "react-router-dom";

const mainTheme = createTheme({

  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: "#0071E3",
    },
    secondary: {
      main: "#625775",
    },
    pureWhite: {
      main: "#FFFF",
    },
    pureBlack: {
      main: "#0000",
    },
    light:
    {
      main: "#4B5563",
      secondary: "#E5E7EB"
    }
  },
  // shadows: {
  //   elevation: 0,
  // },
  text: {

    color: "pink"
  },
});
const defaultTheme = createTheme();

let theme = createTheme(mainTheme,{
  palette: {
    primary: {
      main: "#1B6DE6",
    },
    secondary: {
      main: "#c8b7cb"
    },
    black: defaultTheme.palette.augmentColor({
      color: { main: '#000000' },
      name: 'black'
    }),
    green: defaultTheme.palette.augmentColor({
      color: { main: '#41a11a' },
      name: 'green'
    }),
    red: defaultTheme.palette.augmentColor({
      color: { main: '#FF1E4B' },
      name: 'red'
    }),
    lightBlue: defaultTheme.palette.augmentColor({
      color: { main: '#F1F9FD' },
      name: 'lightBlue'
    }),
    // Request
    danube: defaultTheme.palette.augmentColor({
      color: { main: '#64AAD0' },
      name: 'danube'
    }),
    pink: defaultTheme.palette.augmentColor({
      color: { main: '#ec4d96' },
      name: 'pink'
    }),
    divGrey: defaultTheme.palette.augmentColor({
      color: { main: '#F1F5F8' },
      name: 'divGrey'
    }),
    darkGrey: defaultTheme.palette.augmentColor({
      color: { main: '#8B98AB' },
      name: 'darkGrey'
    }),
    darkBlue: defaultTheme.palette.augmentColor({
      color: { main: '#002864' },
      name: 'darkBlue'
    }),
    orange: defaultTheme.palette.augmentColor({
      color: { main: '#FFB978' },
      name: 'orange'
    }),
    blue: defaultTheme.palette.augmentColor({
      color: { main: '#1B6DE6' },
      name: 'blue'
    }),
    lightGreen: defaultTheme.palette.augmentColor({
      color: { main: '#F1FDF4' },
      name: 'lightGreen'
    }),
    lightRed: defaultTheme.palette.augmentColor({
      color: { main: '#FFE8EE' },
      name: 'lightRed'
    }),
    // color
    inQueue: defaultTheme.palette.augmentColor({
      color: { main: '#C4CFED' },
      name: 'inQueue'
    }),
    diagnose: defaultTheme.palette.augmentColor({
      color: { main: '#6DA085' },
      name: 'diagnose'
    }),
    notifyCustomer: defaultTheme.palette.augmentColor({
      color: { main: '#E277B0' },
      name: 'notifyCustomer'
    }),
    accepted: defaultTheme.palette.augmentColor({
      color: { main: '#41a11a' },
      name: 'accepted'
    }),
    waitingForCustomerDecision: defaultTheme.palette.augmentColor({
      color: { main: '#6D6E6F' },
      name: 'waitingForCustomerDecision'
    }),
    inRepair: defaultTheme.palette.augmentColor({
      color: { main: '#5072CB' },
      name: 'inRepair'
    }),
    waitingForParts: defaultTheme.palette.augmentColor({
      color: { main: '#9CE8E4' },
      name: 'waitingForParts'
    }),
    inDelivery: defaultTheme.palette.augmentColor({
      color: { main: '#E9BF78' },
      name: 'inDelivery'
    }),
    qualityAssurance: defaultTheme.palette.augmentColor({
      color: { main: '#C6C4C4' },
      name: 'qualityAssurance'
    }),
    readyForPickUp: defaultTheme.palette.augmentColor({
      color: { main: '#72AEC8' },
      name: 'readyForPickUp'
    }),
    deliveredToCustomer: defaultTheme.palette.augmentColor({
      color: { main: '#DF9277' },
      name: 'deliveredToCustomer'
    }),
  },
  label: {
    textTransform: "none", // removes uppercase transformation
  },
})


theme = createTheme(theme, {

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 5
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divGrey.main
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ".MuiFilledInput-root::after": {
            border: 'none'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            backgroundColor: theme.palette.lightBlue.main,
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: '4px'
          },
          "& .MuiSelect-filled::before": {
            border: 'none',
          },
          "& .MuiSelect-filled::after": {
            border: 'none',
          },
          "& .MuiFilledInput-root::before": {
            border: 'none',
          },
          "& .MuiFilledInput-root::after": {
            border: 'none',
          },
          "& .MuiFilledInput-root:hover::before": {
            border: 'none',
          },
          "& .MuiFilledInput-root:hover": {
            border: '1px solid',
            backgroundColor: theme.palette.lightBlue,
          },
          "& .MuiInputLabel-filled": {
            fontWeight: 700,
            color: theme.palette.darkGrey.main
          },
          "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
            border: 'none',
          },
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '100%', display: 'flex', flexDirection: 'row', padding: '5px'
        },
      }
    }
    ,
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            backgroundColor: theme.palette.lightBlue.main,
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            '&.Mui-focused': {
              borderColor: 'black',
              backgroundColor: theme.palette.lightBlue.main,
              '& > .MuiSvgIcon-root': {
                color: theme.palette.blue.main,
              },
            },
          },
          "& .MuiFilledInput-root::before": {
            border: 'none',
          },
          "& .MuiFilledInput-root::after": {
            border: 'none',
          },
          "& .MuiFilledInput-root:hover": {
            border: '1px solid',
            borderBottom: '1px solid',
            backgroundColor: '#F1F9FD',
          },
          "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
            border: 'none',
          },
          "& .MuiInputLabel-filled": {
            fontWeight: 700,
            color: theme.palette.darkGrey.main,
            "&.Mui-focused": {
              color: theme.palette.blue.main,

            }
          },
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem'
        }
      }
    }
  }
});

function App() {
  return (

    <div >
      <ThemeProvider theme={theme}>
        <Header />
        <Box sx={{ marginTop: "8px" }}>

          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/sobre-nosotros' element={<AboutUs />} />
            <Route exact path='/ponte-en-contacto' element={<ContactUs />} />
            <Route exact path='/reparacion' element={<RepairForm />} />
            <Route exact path='/repair-form/' element={<RepairForm />} />
            <Route exact path='/repair-form' element={<RepairForm />} />
            <Route exact path='/politica-de-privacidad' element={<PrivacyPolicy />} />
            <Route path="*" element={<NoMatch />} />

          </Routes>
        </Box>
        <Footer />
      </ThemeProvider>
    </div>

  );
}

export default App;
