import * as React from "react";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "../../components/stylized-components/ShadowPaper";

import Typography from "@mui/material/Typography";
import Section from "../../components/section/Section";
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';

import axios from 'axios';


// ICONS

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Images
import Mobile from "./PhoneRepair.png";
import Tablet from "./TabletRepair.png";
import PC from "./PcRepair.png";
import Laptop from "./LaptopRepair.png";
import Other from "./OtherRepair.png";
import Store from "./StorePickup.png";
import Delivery from "./HomePickup.png";


// Lists
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';



const steps = ['', '', '', ''];

const RepairForm = () => {
  const url = 'https://api.dalvado.com:9980';

  function handleOnChange(value) {
    this.setState({
      phone: value
    });
  }

  const CategroyButton = (props) => {
    return <Button value={props.value} onClick={props.onClick}><Box>
      <Box
        component="img"
        sx={{ maxWidth: "120px", padding: "5px" }}
        src={props.image}
      /><Typography align="center">{props.name}</Typography>
    </Box></Button>;
  };


  const [brands, setBrands] = React.useState([]);


  const models = [

  ]

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    return step === 1;
  };

  const CategoryTypography = styled(Typography)({
    variant: "h2",
    fontSize: "1.5rem",
    fontWeight: "600"
  });

  const [formValid, setFormValid] = React.useState({
    isDeviceDataValid: true,
    isClientDataValid: true,
  })


  // Form
  const [repairData, setRepairData] = React.useState(
    {
      deviceType: "",
      brand: "",
      model: "",
      malfunction: "",
      homeDelivery: false,
      clientPhoneNumber: "",
      clientName: "",
      clientAddress: "",
      clientEmail: "",
      clientNote: "",
      consent: false
    }
  );
  const [submitStatus, setSubmitStatus] = React.useState({
    statusCode: 0,
    statusMessage :""
  });

  const inputChangeHandler = (event) => {
    setRepairData((prevState) => {
      return { ...prevState, [event.target.id]: event.target.value }
    })

  }

  const handlePhoneChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 9) {
      setRepairData((prevState) => {
        return { ...prevState, ['clientPhoneNumber']: onlyNums }
      })
    } else if (onlyNums.length === 9) {
      const number = onlyNums.replace(
        /(\d{3})(\d{3})(\d{3})/,
        '($1) $2-$3'
      );
      setRepairData((prevState) => {
        return { ...prevState, ['clientPhoneNumber']: number }
      })
    }
  }

  const handleBrandSelect = (value) => {

    setRepairData((prevState) => {
      return { ...prevState, ["brand"]: value.label }
    })
  }

  const clientAddress = () => {
    if (repairData['homeDelivery'] == "true" || repairData['homeDelivery'] == true) {
      return <React.Fragment>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" justifyContent="center" marginBottom={2} marginTop={2}>
          <FormControl fullWidth disabled>
            <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={1}
              label="Provincia"
            >
              <MenuItem value={1}>Valencia</MenuItem>

            </Select>
          </FormControl>
          <FormControl fullWidth disabled>
            <InputLabel id="demo-simple-select-label">Poblacion</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={1}
              label="Poblacion"
            >
              <MenuItem value={1}>Xativa</MenuItem>

            </Select>
          </FormControl>
        </Stack>
        <TextField required error={!formValid['isDeviceDataValid']}
          helperText={!formValid['isDeviceDataValid'] ? "campo requerido" : ""}
          sx={{ marginBottom: 2 }} fullWidth rows={2}
          multiline id="clientAddress"
          value={repairData['clientAddress']}
          onChange={inputChangeHandler} label="Direction" />
      </React.Fragment>
    }
    else {
      return null;
    }

  }


  const handleDeviceTypeSet = (event) => {
    setRepairData((prevState) => {
      return { ...prevState, ['deviceType']: event.currentTarget.value }
    });
    handleNext();
  }

  const handleConsent = (event) => {
    setRepairData((prevState) => {
      return { ...prevState, ['consent']: event.target.checked }
    });
  }

  const handleHomeDelivery = (event) => {
    setRepairData((prevState) => {
      return { ...prevState, ['homeDelivery']: event.currentTarget.value }
    });
    handleNext();
  }

  const validateStep = () => {
    switch (activeStep) {
      case 0:
        return handleNext();


      case 1:


        if (repairData['brand'] === "" || repairData['model'] === "" || repairData['malfunction'] === "") {
          return setFormValid((prevState) => { return { ...prevState, ['isDeviceDataValid']: false } });
        }
        else {
          return handleNext();
        }
      case 3:
        // if(all required field are valid)

        if (repairData['clientName'] === "" || repairData['clientPhone'] === "" || !repairData['consent']) {
          return setFormValid((prevState) => { return { ...prevState, ['isClientDataValid']: false } });

        }


        axios.post(`${url}/entries/entry`, repairData)
          .then(function (response) {
            if (response.status == 201) {
              //Notification and reset form
              setSubmitStatus({statusCode:1, statusMessage:"Nos pondremos en contacto lo antes posible."});
            }
            else
            {
              setSubmitStatus({statusCode:0, statusMessage:"Algo no a functionado bien... pero ne puedes llamar para hacer la solicitud. 642 022 011"});
            }
            handleNext();

          })
          .catch(function (error) {
            // console.log(error);
            setSubmitStatus({statusCode:0, statusMessage:"Algo no a functionado bien... pero ne puedes llamar para hacer la solicitud. 642 022 011"});
            handleNext();
            //Notification that it didn't work well

          });

    }
  }




  const handleContent = (step) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <CategoryTypography sx={{ textAlign: "center" }}>Dispositivo</CategoryTypography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} alignItems="center" justifyContent="center">
              <CategroyButton name="Movil" value="mobile" image={Mobile} onClick={handleDeviceTypeSet} />
              <CategroyButton name="Tablet" value="tablet" image={Tablet} onClick={handleDeviceTypeSet} />
              <CategroyButton name="Ordenador" value="desktop" image={PC} onClick={handleDeviceTypeSet} />
              <CategroyButton name="Portatil" value="laptop" image={Laptop} onClick={handleDeviceTypeSet} />
              <CategroyButton name="Otro" value="other" image={Other} onClick={handleDeviceTypeSet} />
            </Stack>
          </React.Fragment>
        )
      case 1:
        return (
          <React.Fragment>
            <CategoryTypography sx={{ textAlign: "center" }}>Informacion del dispositivo</CategoryTypography>

            <Box marginTop={2} >
              <Autocomplete

                sx={{ marginBottom: 2 }}
                disablePortal
                autoSelect
                id="brand"
                display="inline"
                value={repairData['brand']}
                onFocus={()=>{
                  axios.get(`${url}/devices/brands`)
                  .then(function (response) {
                    if (response.status == 200) {
                      //Notification and reset form
                      // setSubmitStatus("Nos pondremos en contacto lo antes posible.")
                      const newBrands = [];
                      response.data.map((element,index) =>{
                        newBrands.push({label: element.brand, key:index})
                       
                      })
                      setBrands(newBrands);
                    }

                  })
                  .catch(function (error) {

                    //Notification that it didn't work well

                  });
                }}
                options={brands}
                onChange={(e, v) => { if (v == null) { v = ""; } handleBrandSelect(v) }}
                renderInput={(params) => <TextField required error={!formValid['isDeviceDataValid']} helperText={!formValid['isDeviceDataValid'] ? "campo requerido" : ""} {...params} label="Marca" />}
              />

              <TextField required error={!formValid['isDeviceDataValid']} helperText={!formValid['isDeviceDataValid'] ? "campo requerido" : ""} sx={{ marginBottom: 2 }} fullWidth multiline id="model" onChange={inputChangeHandler} value={repairData['model']} label="Model" />

            </Box>
            <TextField required error={!formValid['isDeviceDataValid']} helperText={!formValid['isDeviceDataValid'] ? "campo requerido" : ""} sx={{ marginBottom: 2 }} fullWidth rows={2} multiline id="malfunction" onChange={inputChangeHandler} value={repairData['malfunction']} label="Problema" />

          </React.Fragment>
        )
      case 2:
        return (
          <React.Fragment>

            <CategoryTypography sx={{ textAlign: "center" }}>Tipo de entrega y recogida</CategoryTypography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={6} alignItems="center" justifyContent="center">
              <CategroyButton name="Recogida a domicilio" image={Delivery} value={true} onClick={handleHomeDelivery} />
              <CategroyButton name="Visita a tienda" image={Store} value={false} onClick={handleHomeDelivery} />
            </Stack>

          </React.Fragment>

        )
      case 3:
        return (
          <React.Fragment>

            <CategoryTypography sx={{ textAlign: "center" }}>Datos de contacto</CategoryTypography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" justifyContent="center" marginBottom={2} marginTop={2}>
              <TextField required error={!formValid['isClientDataValid']} helperText={!formValid['isClientDataValid'] ? "campo requerido" : ""} sx={{ marginBottom: 0 }} fullWidth multiline id="clientName" onChange={inputChangeHandler} value={repairData['clientName']} label="Nombre" />
              <TextField required error={!formValid['isClientDataValid']} helperText={!formValid['isClientDataValid'] ? "campo requerido" : ""} sx={{ marginBottom: 0 }} fullWidth multiline id="clientPhone" onChange={handlePhoneChange} value={repairData['clientPhoneNumber']} label="Número de teléfono" />



            </Stack>
            <TextField sx={{ marginBottom: 2 }} fullWidth multiline id="clientEmail" onChange={inputChangeHandler} value={repairData['clientEmail']} label="Email" />



            {clientAddress()}

            <TextField
              sx={{ marginBottom: 2 }}
              fullWidth
              rows={2}
              multiline id="clientNote"
              value={repairData['clientNote']}
              onChange={inputChangeHandler} label="Nota" />
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={repairData['consent']} id="consent" onChange={handleConsent} />} label="Acceptas compartir tus datos " />
            </FormGroup>
            {!formValid['isClientDataValid'] ? <FormHelperText>Tienes que acceptar la politicas</FormHelperText>
              : null}
          </React.Fragment>

        )

    }
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };




  return (
    <React.Fragment>
      <Section background='linear-gradient(to right bottom, #2FA1FE, #0555DE)' borderBottomRightRadius="80% 40vw" borderBottomLeftRadius="80% 40vw" color="white" spacing={7} content={
        <React.Fragment>
          <Grid item lg={12} >
            <Typography textAlign="center" variant="h1" fontSize="2rem" fontWeight="600">
              ¿Qué dispositivo podemos reparar para usted hoy?
            </Typography>
          </Grid>

          <Grid item="center" lg={12} xs={12}>
            <Paper alphaOne="1" sx={{ maxWidth: "700px", marginX: "auto" }}>
              <Box padding={3}>
                <Stepper activeStep={activeStep} sx={{ marginBottom: "5%" }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      {submitStatus['statusCode']== 1?   <Alert severity="success">Nos pondremos en contacto lo antes posible.</Alert> : <Alert severity="error">Algo no a functionado bien... pero ne puedes llamar para hacer la solicitud. 642 022 011</Alert>}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment >
                    <Box minHeight="200px">
                      {handleContent(activeStep)}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Atrás
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />

                      {activeStep === 1 || activeStep == 3 ?
                        <Button onClick={validateStep}>
                          {activeStep === steps.length - 1 ? 'Enviar' : 'Siguente'}
                        </Button>
                        : null}

                    </Box>

                  </React.Fragment>
                )}

              </Box>
            </Paper>
          </Grid>


        </React.Fragment>
      } />

      <Section alignItems="center" spacing={10} content={
        <React.Fragment>
          <Grid item xs={12} lg={4}>
            <List sx={{ width: '100%', maxWidth: 360, marginX: "auto" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <svg width={0} height={0}>
                    <linearGradient id="ManageSearchIcon" x1={1} y1={0} x2={1} y2={1}>
                      <stop offset={0} stopColor="#FC383C" />
                      <stop offset={1} stopColor="#FE7F2D" />
                    </linearGradient>
                  </svg>
                  <ManageSearchIcon sx={{ fontSize: "3rem", margin: "auto", fill: "url(#ManageSearchIcon)" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={<React.Fragment>
                    <Typography

                      fontSize="1.5rem"
                      fontWeight="600"
                      minHeight="80px"
                      lineHeight="1.2"

                    >
                      Diagnostico es gratuito
                    </Typography>

                  </React.Fragment>

                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Nuestro tecnicos te va a diagnosticar el dispositivo GRATUITO!
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} lg={4}>
            <List sx={{ width: '100%', maxWidth: 360, marginX: "auto" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <svg width={0} height={0}>
                    <linearGradient id="DateRangeIcon" x1={1} y1={0} x2={1} y2={1}>
                      <stop offset={0} stopColor="#80D24B" />
                      <stop offset={1} stopColor="#48C694" />
                    </linearGradient>
                  </svg>
                  <DateRangeIcon sx={{ fontSize: "3rem", margin: "auto", fill: "url(#DateRangeIcon)" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={<React.Fragment>
                    <Typography

                      fontSize="1.5rem"
                      fontWeight="600"
                      minHeight="80px"
                      lineHeight="1.2"


                    >
                      Tres meses de garantía
                    </Typography>

                  </React.Fragment>

                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Ofrecemos 90 días de garantía para nuestro servicio
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} lg={4}>
            <List sx={{ width: '100%', maxWidth: 360, marginX: "auto" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <svg width={0} height={0}>
                    <linearGradient id="DeliveryDiningIcon" x1={1} y1={0} x2={1} y2={1}>
                      <stop offset={0} stopColor="#3489F6" />
                      <stop offset={1} stopColor="#1F79EA" />
                    </linearGradient>
                  </svg>
                  <DeliveryDiningIcon sx={{ fontSize: "3rem", margin: "auto", fill: "url(#DeliveryDiningIcon)" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={<React.Fragment>
                    <Typography
                      fontSize="1.5rem"
                      fontWeight="600"
                      minHeight="80px"
                      lineHeight="1.2"
                    >
                      Nos encargamos de la entrega
                    </Typography>

                  </React.Fragment>

                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Sabemos que tu tiempo es importante y por esto venimos nosotros a tu direction.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </React.Fragment>
      } />


    </React.Fragment>
  );
};

export default RepairForm;
