import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "../../components/stylized-components/ShadowPaper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import GameVariants from "../../components/variants/GamesSkeleton";
import Section from "../../components/section/Section";

import StoreImg from "./store1.jpg";
import StoreImg2  from "./DSC_0277.jpg";
import StoreImg3  from "./interior1.jpg";
import StoreImg4  from "./interior2.jpg";



// ICONS
import VisibilityIcon from '@mui/icons-material/Visibility';
import FaceIcon from '@mui/icons-material/Face';

const Games = () => {
  return (
    <React.Fragment>
      <Section alignItems="center" background='linear-gradient(to right bottom, #E7F2FF, #E7F2FF)' content={
        <React.Fragment>
          <Grid item lg={12}>
            <Box textAlign="center" paddingBottom={15} paddingTop={5}>
              <Typography
                fontWeight="600"
                fontSize="2.625rem"
                component="h2"
                lineHeight="1.1"
                display="inline "
              >
                Cambiamos la forma en la que se
              </Typography>
              <Box >
                <Typography color="#1576ED"
                  fontWeight="600"
                  fontSize="2.625rem"
                  component="h2"
                  lineHeight="1.1"

                  marginX={2}
                  display="inline "
                >reparan</Typography>
                <Typography
                  fontWeight="600"
                  fontSize="2.625rem"
                  component="h2"
                  lineHeight="1.1"
                  display="inline "
                >los electrónicos.</Typography>
              </Box>
            </Box>
          </Grid>

        </React.Fragment>
      } />
      <Section alignItems="center" marginTop={-20} content={
        <React.Fragment>

          <Grid item lg={6}>
            <Paper alphaOne="0.2">
              <Box padding={5}>
                <Typography
                  align="left"
                  fontWeight="600"
                  fontSize="2rem"
                  component="h2"
                  lineHeight="1.1"
                  marginBottom="25px"
                >
                  Misión
                </Typography>
                <Typography
                  align="left"
                  fontSize="1.5rem"
                  component="h2"
                  lineHeight="1.1"
                  marginBottom="25px"
                >
                  Facilitar el proceso de reparación implementado tecnología y soluciones de última hora sin comprometer la calidad.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item lg={6}>
          <Paper alphaOne="0.2">
              <Box padding={5}>
                <Typography
                  align="left"
                  fontWeight="600"
                  fontSize="2rem"
                  component="h2"
                  lineHeight="1.1"
                  marginBottom="25px"
                >
                  Visión
                </Typography>
                <Typography
                  align="left"
                  fontSize="1.5rem"
                  component="h2"
                  lineHeight="1.1"
                  marginBottom="25px"
                >
                  La mayoría trabajan con métodos anticuados pero es la era de la velocidad y asi tiene que ser y las reparaciones.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </React.Fragment>
      } />
      <Section alignItems="center" content={
        <React.Fragment>

          <Grid item lg={12}>
          <Paper alphaOne="0.2">
              <Box padding={5}>
                <Typography
                  align="left"
                  fontWeight="600"
                  fontSize="2rem"
                  component="h2"
                  lineHeight="1.1"
                  marginBottom="25px"
                >
                  La marca
                </Typography>
                <Typography
                  fontSize="18px"
                  align="left"
                  component="h2"
                  lineHeight="1.1"
                  marginBottom="25px"
                >
                  Dalvado fue fundado en 2020, pero el equipo detrás tiene unas décadas de experencia que quiere ofrecer un servicio a un nivel superior del que ofrece la competencia.
                </Typography>
              </Box>
            </Paper>
          </Grid>

        </React.Fragment>
      } />

      <Section alignItems="center" content={
        <React.Fragment>

          <Grid item xs={12} lg={6}>
            <Box
              align="left"
              component="img"
              sx={{ height: "100%", width: "100%" }}
              alt="outside of dalvado store"
              borderRadius={1}

              src={StoreImg}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              align="left"
              component="img"
              sx={{ height: "100%", width: "100%" }}
              alt="wall with phone cases in dalvado store"
              borderRadius={1}

              src={StoreImg2}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              align="left"
              component="img"
              sx={{ height: "100%", width: "100%" }}
              alt="dalvado store entry"
              borderRadius={1}

              src={StoreImg3}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              align="left"
              component="img"
              sx={{ height: "100%", width: "100%" }}
              alt="dalvado store reception"
              borderRadius={1}

              src={StoreImg4}
            />
          </Grid>

        </React.Fragment>
      } />

      <Section alignItems="center" padding={0} content={
        <React.Fragment>
          <Grid item xs={12}>
            <Box padding={5}>
              <Typography
                align="center"
                fontWeight="600"
                fontSize="18px"
                component="h2"
                color="#1576ED"
              >
                Las valores de Dalvado
              </Typography>
              <Typography
                marginTop={-1}
                align="center"
                fontWeight="600"
                fontSize="2rem"
                component="h2"
                lineHeight="1"
              >
                Lo que representamos

              </Typography>
            </Box>
          </Grid>


        </React.Fragment>
      } />


      <Section alignItems="center" spacing={10} content={
        <React.Fragment>

          <Grid item lg={6} xs={12} align="center">
            <svg width={0} height={0}>
              <linearGradient id="VisibilityIcon" x1={1} y1={0} x2={1} y2={1}>
                <stop offset={0} stopColor="#3489F6" />
                <stop offset={1} stopColor="#1F79EA" />
              </linearGradient>
            </svg>
            <VisibilityIcon fontSize="large" sx={{ fill: "url(#VisibilityIcon)" }} />
            <Typography
              align="center"
              fontWeight="600"
              fontSize="1.5rem"
              component="h2"
              lineHeight="1.1"
              marginBottom="25px"
            >

              Transparencia
            </Typography>
            <Typography
              fontSize="18px"
              align="center"
              component="h2"
              lineHeight="1.1"
              marginBottom="25px"
            >
              Administrar un negocio es complicado, acceptar las errores es dificil, pero siempre ofrecemos transparencia para nuestro clientes y nuestro socios!
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12} align="center">
            <svg width={0} height={0}>
              <linearGradient id="FaceIcon" x1={1} y1={0} x2={1} y2={1}>
                <stop offset={0} stopColor="#FE6B8B" />
                <stop offset={1} stopColor="#FF8E53" />
              </linearGradient>
            </svg>
            <FaceIcon fontSize="large" sx={{ fill: "url(#FaceIcon)" }} />
            <Typography
              align="center"
              fontWeight="600"
              fontSize="1.5rem"
              component="h2"
              lineHeight="1.1"
              marginBottom="25px"
            >

              Cliente primero
            </Typography>
            <Typography
              fontSize="18px"
              align="center"
              component="h2"
              lineHeight="1.1"
              marginBottom="25px"
            >
              Los servicios que lo ofrecemos estan concepidos con el satisfecho del cliente en el primer lugar y tambien si es viable para nuestro negocio.
            </Typography>
          </Grid>


        </React.Fragment>
      } />
    </React.Fragment>
  );
};

export default Games;
