import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const NoMatch = () => {

    return (
        <React.Fragment>
            <Container sx={{height:"100%"}}>
                <Box padding={1} marginTop={10}  marginBottom={4} sx={{minHeight:"70vh"}} textAlign="center">
                    <Typography variant="h1" fontSize="3.5rem"  fontWeight="500" color="inherit" textAlign="center" marginBottom={4}>
                        La página que intentas solicitar
                        no está en el servidor (Error 404)
                    </Typography>
                    <Button  variant="contained" disableElevation  color="primary" component={RouterLink} to="/" sx={{paddingX:"10%"}}>Inicio </Button>

                </Box>

            </Container>

        </React.Fragment>
    )
}

export default NoMatch;