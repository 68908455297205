import * as React from 'react';
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";




const PrivacyPolicy = () => {

    return (
        <React.Fragment>
            <Container>
                <Typography>
                    <Typography variant="h1" fontSize="2rem">
                        Política de Privacidad
                    </Typography>
                    Última actualización 27 de noviembre de 2021


                    <br /><br />Gracias por elegir ser parte de nuestra comunidad en dalvado (\ "Compañía, \" \ "nosotros, \" \ "nos, \" o \ "nuestro \"). Estamos comprometidos a proteger su información personal y su derecho a la privacidad. Si tiene alguna pregunta o inquietud sobre este aviso de privacidad o nuestras prácticas con respecto a su información personal, comuníquese con nosotros a contact@dalvado.com.

                    <br /><br />Este aviso de privacidad describe cómo podemos usar su información si usted:
                    <br />Visite nuestro sitio web en dalvado.es
                    <br />Interactúe con nosotros de otras formas relacionadas, incluidas ventas, marketing o eventos.
                    <br />En este aviso de privacidad, si nos referimos a:
                    <br />\ "Sitio web \", nos referimos a cualquier sitio web nuestro que haga referencia o enlace a esta política
                    <br />\ "Servicios \", nos referimos a nuestro sitio web y otros servicios relacionados, incluidas ventas, marketing o eventos
                    <br />El propósito de este aviso de privacidad es explicarle de la manera más clara posible qué información recopilamos, cómo la usamos y qué derechos tiene en relación con ella. Si hay algún término en este aviso de privacidad con el que no está de acuerdo, interrumpa el uso de nuestros Servicios de inmediato.

                    <br /><br />Lea atentamente este aviso de privacidad, ya que le ayudará a comprender qué hacemos con la información que recopilamos.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        TABLA DE CONTENIDO
                    </Typography>

                    <br />1. ¿QUÉ INFORMACIÓN RECOPILAMOS?
                    <br />2. ¿CÓMO UTILIZAMOS SU INFORMACIÓN?
                    <br />3. ¿SE COMPARTIRÁ SU INFORMACIÓN CON ALGUIEN?
                    <br />4. ¿UTILIZAMOS COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO?
                    <br />5. ¿POR CUÁNTO TIEMPO CONSERVAMOS SU INFORMACIÓN?
                    <br />6. ¿CÓMO MANTENEMOS SEGURA SU INFORMACIÓN?
                    <br />7. ¿RECOPILAMOS INFORMACIÓN DE MENORES?
                    <br />8. ¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD?
                    <br />9. CONTROLES PARA LAS CARACTERÍSTICAS DE NO SEGUIR
                    <br />10. ¿HACEMOS ACTUALIZACIONES A ESTE AVISO?
                    <br />11. ¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?
                    <br />12. ¿CÓMO PUEDE REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE RECOPILAMOS DE USTED?
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        1. ¿QUÉ INFORMACIÓN RECOPILAMOS?
                    </Typography>
                    <Typography paragraph sx={{ whiteSpace: 'pre-line' }}>
                        Información personal que nos divulga

                        <br /> <br /> En resumen: recopilamos la información personal que nos proporciona.

                        <br /><br /> Recopilamos información personal que nos proporciona voluntariamente cuando expresa su interés en obtener información sobre nosotros o nuestros productos y servicios, cuando participa en actividades en el sitio web o cuando se comunica con nosotros.

                        <br /><br /> La información personal que recopilamos depende del contexto de sus interacciones con nosotros y el sitio web, las elecciones que haga y los productos y funciones que utilice. La información personal que recopilamos puede incluir lo siguiente:

                        <br /><br /> Información personal proporcionada por usted. Recopilamos nombres; números de teléfono; correos electrónicos; direcciones de correo; y otra información similar.

                        <br /><br /> Toda la información personal que nos proporcione debe ser verdadera, completa y precisa, y debe notificarnos cualquier cambio en dicha información personal.

                        <br /><br /> Información recopilada automáticamente

                        <br /><br /> En resumen: parte de la información, como su dirección de Protocolo de Internet (IP) y / o las características del navegador y del dispositivo, se recopila automáticamente cuando visita nuestro sitio web.

                        <br /><br /> Recopilamos automáticamente cierta información cuando visita, usa o navega por el sitio web. Esta información no revela su identidad específica (como su nombre o información de contacto), pero puede incluir información de uso y dispositivo, como su dirección IP, características del navegador y del dispositivo, sistema operativo, preferencias de idioma, URL de referencia, nombre del dispositivo, país, ubicación. , información sobre cómo y cuándo utiliza nuestro sitio web y otra información técnica. Esta información es principalmente necesaria para mantener la seguridad y el funcionamiento de nuestro sitio web, y para nuestros fines de análisis e informes internos.

                        <br /><br /> Como muchas empresas, también recopilamos información a través de cookies y tecnologías similares.

                        <br /><br /> La información que recopilamos incluye:
                        <br />Datos de registro y uso. Los datos de registro y uso son información relacionada con el servicio, diagnóstico, uso y rendimiento que nuestros servidores recopilan automáticamente cuando accede o utiliza nuestro sitio web y que registramos en archivos de registro. Dependiendo de cómo interactúe con nosotros, estos datos de registro pueden incluir su dirección IP, información del dispositivo, tipo de navegador y configuración e información sobre su actividad en el sitio web (como las marcas de fecha / hora asociadas con su uso, páginas y archivos vistos, búsquedas y otras acciones que realiza, como las funciones que usa), información de eventos del dispositivo (como la actividad del sistema, informes de errores (a veces llamados 'volcados por caída') y configuraciones de hardware).
                        <br />Datos del dispositivo. Recopilamos datos del dispositivo, como información sobre su computadora, teléfono, tableta u otro dispositivo que utilice para acceder al sitio web. Dependiendo del dispositivo utilizado, estos datos del dispositivo pueden incluir información como su dirección IP (o servidor proxy), números de identificación de dispositivo y aplicación, ubicación, tipo de navegador, modelo de hardware Proveedor de servicios de Internet y / o operador de telefonía móvil, sistema operativo y configuración del sistema. información.
                    </Typography>
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        2. ¿CÓMO UTILIZAMOS SU INFORMACIÓN?
                    </Typography>
                    <Typography paragraph >
                        En resumen: procesamos su información con fines basados ​​en intereses comerciales legítimos, el cumplimiento de nuestro contrato con usted, el cumplimiento de nuestras obligaciones legales y / o su consentimiento.

                        <br /><br />Usamos la información personal recopilada a través de nuestro sitio web para una variedad de fines comerciales que se describen a continuación. Procesamos su información personal para estos fines basándonos en nuestros intereses comerciales legítimos, para celebrar o ejecutar un contrato con usted, con su consentimiento, y / o para cumplir con nuestras obligaciones legales. Indicamos los motivos de procesamiento específicos en los que confiamos junto a cada propósito que se enumera a continuación.

                        <br /><br />Usamos la información que recopilamos o recibimos:
                        <br />Para enviarle información administrativa. Podemos utilizar su información personal para enviarle información sobre productos, servicios y nuevas funciones y / o información sobre cambios en nuestros términos, condiciones y políticas.
                        <br />Para proteger nuestros Servicios. Podemos utilizar su información como parte de nuestros esfuerzos para mantener nuestro sitio web seguro y protegido (por ejemplo, para el monitoreo y la prevención de fraudes).
                        <br />Para hacer cumplir nuestros términos, condiciones y políticas con fines comerciales, para cumplir con los requisitos legales y reglamentarios o en relación con nuestro contrato.
                        <br />Para responder a solicitudes legales y prevenir daños. Si recibimos una citación u otra solicitud legal, es posible que debamos inspeccionar los datos que tenemos para determinar cómo responder.
                        <br />Cumplir y gestionar sus pedidos. Podemos utilizar su información para cumplir y administrar sus pedidos, pagos, devoluciones e intercambios realizados a través del sitio web.
                        <br />Administrar sorteos y concursos. Podemos utilizar su información para administrar sorteos de premios y concursos cuando elija participar en nuestros concursos.
                        <br />Para entregar y facilitar la entrega de servicios al usuario. Podemos utilizar su información para brindarle el servicio solicitado.
                        <br />Responder a las consultas de los usuarios / ofrecer soporte a los usuarios. Podemos utilizar su información para responder a sus consultas y resolver cualquier problema potencial que pueda tener con el uso de nuestros Servicios.
                    </Typography>
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        3. ¿SE COMPARTIRÁ SU INFORMACIÓN CON ALGUIEN?
                    </Typography>
                    En resumen: solo compartimos información con su consentimiento, para cumplir con las leyes, para brindarle servicios, para proteger sus derechos o para cumplir con las obligaciones comerciales.

                    <br /><br />Podemos procesar o compartir sus datos que tenemos en base a la siguiente base legal:
                    <br />Consentimiento: podemos procesar sus datos si nos ha dado su consentimiento específico para usar su información personal para un propósito específico.
                    <br />Intereses legítimos: podemos procesar sus datos cuando sea razonablemente necesario para lograr nuestros intereses comerciales legítimos.
                    <br />Ejecución de un contrato: cuando hayamos celebrado un contrato con usted, podemos procesar su información personal para cumplir con los términos de nuestro contrato.
                    <br />Obligaciones legales: podemos divulgar su información cuando estemos legalmente obligados a hacerlo para cumplir con la ley aplicable, las solicitudes gubernamentales, un procedimiento judicial, una orden judicial o un proceso legal, como en respuesta a una orden judicial o una citación ( incluso en respuesta a las autoridades públicas para cumplir con los requisitos de seguridad nacional o de aplicación de la ley).
                    <br />Intereses vitales: podemos divulgar su información cuando creamos que es necesario investigar, prevenir o tomar medidas con respecto a posibles violaciones de nuestras políticas, sospecha de fraude, situaciones que involucren amenazas potenciales a la seguridad de cualquier persona y actividades ilegales, o como evidencia en litigio en el que estamos involucrados.
                    <br />Más específicamente, es posible que necesitemos procesar sus datos o compartir su información personal en las siguientes situaciones:
                    <br />Transferencias comerciales. Podemos compartir o transferir su información en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la empresa, financiación o adquisición de la totalidad o una parte de nuestro negocio a otra empresa.
                    <br />API de Google Maps Platform. Podemos compartir su información con ciertas API de Google Maps Platform (por ejemplo, API de Google Maps, API de lugar). Para obtener más información sobre la Política de privacidad de Google, consulte este enlace. Obtenemos y almacenamos en su dispositivo ('caché') su ubicación. Puede revocar su consentimiento en cualquier momento comunicándose con nosotros a la información de contacto proporcionada al final de este documento.
                    <br />Compañeros de negocio. Podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        4. ¿UTILIZAMOS COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO?
                    </Typography>
                     En resumen: podemos utilizar cookies y otras tecnologías de seguimiento para recopilar y almacenar su información.

                    <br /><br /> Podemos utilizar cookies y tecnologías de seguimiento similares (como balizas web y píxeles) para acceder o almacenar información. La información específica sobre cómo usamos dichas tecnologías y cómo puede rechazar ciertas cookies se establece en nuestro Aviso de cookies.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        5. ¿POR CUÁNTO TIEMPO CONSERVAMOS SU INFORMACIÓN?
                    </Typography>
                   En resumen: Conservamos su información durante el tiempo que sea necesario para cumplir con los propósitos descritos en este aviso de privacidad, a menos que la ley exija lo contrario.

                    <br /><br />Solo conservaremos su información personal durante el tiempo que sea necesario para los fines establecidos en este aviso de privacidad, a menos que la ley exija o permita un período de retención más prolongado (como impuestos, contabilidad u otros requisitos legales). Ningún propósito de este aviso requerirá que conservemos su información personal durante más de 1 año.

                    <br /><br />Cuando no tengamos una necesidad comercial legítima continua para procesar su información personal, eliminaremos o anonimizaremos dicha información o, si esto no es posible (por ejemplo, porque su información personal se ha almacenado en archivos de respaldo), lo haremos de manera segura almacenar su información personal y aislarla de cualquier procesamiento posterior hasta que sea posible la eliminación.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        6. ¿CÓMO MANTENEMOS SEGURA SU INFORMACIÓN?
                    </Typography>
                    En resumen: nuestro objetivo es proteger su información personal a través de un sistema de medidas de seguridad organizativas y técnicas.

                    <br /><br />Hemos implementado medidas de seguridad técnicas y organizativas apropiadas diseñadas para proteger la seguridad de cualquier información personal que procesamos. Sin embargo, a pesar de nuestras salvaguardas y esfuerzos para proteger su información, no se puede garantizar que ninguna transmisión electrónica a través de Internet o tecnología de almacenamiento de información sea 100% segura, por lo que no podemos prometer ni garantizar que los piratas informáticos, los ciberdelincuentes u otros terceros no autorizados no serán capaz de vencer nuestra seguridad y recopilar, acceder, robar o modificar su información de manera inapropiada. Aunque haremos todo lo posible para proteger su información personal, la transmisión de información personal hacia y desde nuestro sitio web es bajo su propio riesgo. Solo debe acceder al sitio web dentro de un entorno seguro.

                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        7. ¿RECOPILAMOS INFORMACIÓN DE MENORES?
                    </Typography>
                    En resumen: no recopilamos ni comercializamos a sabiendas datos de niños menores de 18 años.

                    <br /><br />No solicitamos a sabiendas datos ni comercializamos a niños menores de 18 años. Al utilizar el sitio web, usted declara que tiene al menos 18 años o que es el padre o tutor de dicho menor y da su consentimiento para que dicho menor dependiente utilice el sitio web. Si nos enteramos de que se ha recopilado información personal de usuarios menores de 18 años, desactivaremos la cuenta y tomaremos medidas razonables para eliminar de inmediato dichos datos de nuestros registros. Si tiene conocimiento de algún dato que hayamos recopilado de niños menores de 18 años, comuníquese con nosotros a contact@dalvado.com.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        8. ¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD?
                    </Typography>
                    En resumen: en algunas regiones, como el Espacio Económico Europeo (EEE) y el Reino Unido (Reino Unido), tiene derechos que le permiten un mayor acceso y control sobre su información personal. Puede revisar, cambiar o cancelar su cuenta en cualquier momento.

                    <br /><br /> En algunas regiones (como el EEE y el Reino Unido), tiene ciertos derechos según las leyes de protección de datos aplicables. Estos pueden incluir el derecho (i) a solicitar acceso y obtener una copia de su información personal, (ii) a solicitar rectificación o borrado; (iii) para restringir el procesamiento de su información personal; y (iv) en su caso, a la portabilidad de datos. En determinadas circunstancias, también puede tener derecho a oponerse al procesamiento de su información personal. Para realizar dicha solicitud, utilice los datos de contacto que se proporcionan a continuación. Consideraremos y actuaremos ante cualquier solicitud de acuerdo con las leyes de protección de datos aplicables.

                    <br /><br />Si confiamos en su consentimiento para procesar su información personal, tiene derecho a retirar su consentimiento en cualquier momento. Sin embargo, tenga en cuenta que esto no afectará la legalidad del procesamiento antes de su retiro, ni afectará el procesamiento de su información personal realizado en base a motivos legales de procesamiento distintos al consentimiento.

                    <br /><br />Si reside en el EEE o el Reino Unido y cree que estamos procesando ilegalmente su información personal, también tiene derecho a presentar una queja ante la autoridad supervisora ​​de protección de datos local. Puede encontrar sus datos de contacto aquí: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.

                    <br /><br />Si reside en Suiza, los datos de contacto de las autoridades de protección de datos están disponibles aquí: https://www.edoeb.admin.ch/edoeb/en/home.html.

                    <br /><br />Cookies y tecnologías similares: la mayoría de los navegadores web están configurados para aceptar cookies de forma predeterminada. Si lo prefiere, normalmente puede optar por configurar su navegador para eliminar las cookies y rechazar las cookies. Si elige eliminar las cookies o rechazarlas, esto podría afectar ciertas funciones o servicios de nuestro sitio web. Para excluirse de la publicidad basada en intereses de los anunciantes en nuestro sitio web, visite http://www.aboutads.info/choices/.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        9. CONTROLES PARA LAS CARACTERÍSTICAS DE NO SEGUIR
                    </Typography>
                    La mayoría de los navegadores web y algunos sistemas operativos y aplicaciones móviles incluyen una función o configuración No rastrear (\ "DNT \") que puede activar para indicar su preferencia de privacidad para que no se controlen y recopilen datos sobre sus actividades de navegación en línea. En esta etapa, no se ha finalizado ningún estándar tecnológico uniforme para reconocer e implementar señales DNT. Como tal, actualmente no respondemos a las señales del navegador DNT ni a ningún otro mecanismo que comunique automáticamente su elección de no ser rastreado en línea. Si se adopta un estándar para el seguimiento en línea que debemos seguir en el futuro, le informaremos sobre esa práctica en una versión revisada de este aviso de privacidad.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        10. ¿HACEMOS ACTUALIZACIONES A ESTE AVISO?
                    </Typography>
                    En resumen: Sí, actualizaremos este aviso según sea necesario para cumplir con las leyes pertinentes.

                    <br /><br />Es posible que actualicemos este aviso de privacidad de vez en cuando. La versión actualizada se indicará con una fecha \ "Revisada \" actualizada y la versión actualizada entrará en vigencia tan pronto como sea accesible. Si realizamos cambios sustanciales a este aviso de privacidad, podemos notificarle publicando un aviso de manera prominente de dichos cambios o enviándole directamente una notificación. Le recomendamos que revise este aviso de privacidad con frecuencia para estar informado de cómo protegemos su información.
                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        11. ¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?
                    </Typography>
                    Si tiene preguntas o comentarios sobre este aviso, puede enviarnos un correo electrónico a contact@dalvado.com o por correo postal a:

                    <br />dalvado
                    <br />Calle de la Reina, 40,
                    <br />Xàtiva, Valencia 46800
                    <br />España

                    <Typography variant="h2" marginY={2} fontSize="2rem">
                        12. ¿CÓMO PUEDE REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE RECOPILAMOS DE USTED?
                    </Typography>
                    Según las leyes aplicables de su país, puede tener derecho a solicitar acceso a la información personal que recopilamos de usted, cambiar esa información o eliminarla en algunas circunstancias. Para solicitar la revisión, actualización o eliminación de su información personal, visite: dalvado.es/ponte-en-contacto.
                </Typography>
            </Container>
        </React.Fragment>
    );

}


export default PrivacyPolicy;