import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Section from "../../components/section/Section";
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";

// ICONS
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const ContactUs = () => {
  const [name, setName] = React.useState("Composed TextField");

  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <React.Fragment>
      <Section csx={{ padding: "500px" }} direction='row' alignItems="stretch" spacing={5} content={
        <React.Fragment >

          <Grid item lg={5} >

            <Box sx={{ background: 'linear-gradient(to right bottom, #0E1C35, #080919)' }} padding={5} borderRadius="20px" height='90%' >
              <Typography
                color="white"
                fontWeight="600"
                fontSize="24px"
                component="h2"
                lineHeight="1.5"

              >
                Informacion de contacto
              </Typography>
              <Typography
                color="white"
                lineHeight="1"
                fontWeight="300"
              >
                Completa el formulario y os contactare en 48 horas
              </Typography>
              <Stack spacing={5} marginTop={10}>
                <Box>
                  <Typography
                    display="inline"
                    color="white"
                    fontSize="20px"
                    lineHeight="1"
                    fontWeight="300"> <LocalPhoneIcon sx={{ fontSize: "20px", marginBottom: "-3px" }} /> 642 022 011 </Typography>
                  <Typography display="inline" marginLeft={1} textAlignVertical='top' color="white" fontSize="12px">(Movil y WhatsApp)</Typography>
                </Box>

                <Typography

                  color="white"
                  fontSize="20px"

                  fontWeight="300"> <EmailIcon sx={{ fontSize: "20px", marginBottom: "-3px" }} /> contact@dalvado.com</Typography>
                <Typography
                  color="white"
                  fontSize="20px"
                  lineHeight="1"
                  fontWeight="300"> <LocationOnIcon sx={{ fontSize: "20px", marginBottom: "-3px" }} /> Calle de la Reina, 40, Xativa Valencia</Typography>
              </Stack>


            </Box>

          </Grid>
          <Grid item lg={7}>

            <Box height="100%" borderRadius={5}>
              <Typography
                color="#00104B"
                fontWeight="500"
                variant="h3"
                marginY={2}
                align="left"
              >
                Ponte en contacto
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <TextField fullWidth label="Nombre" id="fullWidth" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      id="fullWidth"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Telefono"
                      id="fullWidth"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Sujeto" id="fullWidth" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Tu mensaje"
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label={<Typography>Acepto las condiciones de <Box component={RouterLink} to="/politica-de-privacidad">Política de Privacidad</Box></Typography>} />
                    </FormGroup>
                  </Grid>




                  <Grid item xs={12}>
                    <Button
                      sx={{ marginTop: "15px", backgroundColor: "#0071E3", textTransform: 'none' }}
                      variant="contained"
                      disableElevation
                    >
                      <SendIcon />
                      <Box paddingX="15px" paddingY="5px">
                        Envia
                      </Box>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

        </React.Fragment>
      } />

    </React.Fragment>
  );
};

export default ContactUs;
