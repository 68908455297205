import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import logo from "../logo.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { BrowserRouter as Router, Link, Link as RouterLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const navigate = useNavigate();

  const handleClick = () => navigate('/')


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  const StyledNavigationButton = styled(Button)({
    color: "black"
  });



  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu

      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button fullWidth variant="text" component={RouterLink} to="/">Inicio</Button>
      </MenuItem>
      <MenuItem>
        <Button fullWidth variant="text" component={RouterLink} to="/sobre-nosotros">Sobre nosotros</Button>
      </MenuItem>
      <MenuItem>
        <Button fullWidth variant="text" component={RouterLink} to="/ponte-en-contacto"   >Ponerse en contacto</Button>
      </MenuItem>
      <MenuItem>
        <Button fullWidth variant="text" href="https://manager.dalvado.es/webapp/">Manager</Button>
      </MenuItem>
    </Menu>
  );

  //  sx={{  backgroundColor: "white"}}
  return (
    <Box sx={{ borderBottom: "1px solid #DDDD" }}>
      <Container maxWidth="lg" >
        <AppBar position="static" sx={{ backgroundColor: "white" }} elevation={0}>
          <Toolbar>
            <Box>
              <Box
                align="left"
                component="img"
                onClick={handleClick}
                sx={{ maxHeight: "40px", padding: "5%" }}
                alt="tastypill"
                src={logo}
              />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex", zIndex: 3 } }}>
              <Stack spacing={2} direction="row">
                <StyledNavigationButton component={RouterLink} to="/">Inicio</StyledNavigationButton>
                <StyledNavigationButton component={RouterLink} to="/sobre-nosotros">Sobre nosotros</StyledNavigationButton>
                <StyledNavigationButton component={RouterLink} to="/ponte-en-contacto"   >Ponerse en contacto</StyledNavigationButton>
                <StyledNavigationButton href="https://manager.dalvado.es/webapp/"  >Manager</StyledNavigationButton>


                {/* <StyledNavigationButton >Trabaja con Nosotros!</StyledNavigationButton> */}
              </Stack>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="primary"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {renderMobileMenu}

      </Container>
    </Box>
  );
}
